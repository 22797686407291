import { Component }                     from '@angular/core';
import {UA}                              from 'jssip';
import * as JsSIP                        from 'jssip'
import {RTCSession}                      from 'jssip/lib/RTCSession';
import {SIP_LOGIN, SIP_PASS, SIP_SERVER, SIP_PORT} from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'sip-client';
  private ua: UA;
  public account: string = '501';
  private options: any;
  private session: RTCSession | null = null;
  sipLogin = SIP_LOGIN;
   text: string = '';

  constructor() {
    JsSIP.debug.enable('JsSIP:*');

    // let socket = new JsSIP.WebSocketInterface('wss://oktell.scloud.ru');
    let socket = new JsSIP.WebSocketInterface(`wss://${SIP_SERVER}:${SIP_PORT}/ws`);

    let configuration = {
      sockets: [socket],
      // внутренний номер
      uri: `sip:${SIP_LOGIN}@${SIP_SERVER}`,

      contact_uri: `sip:${SIP_LOGIN}@${SIP_SERVER}`,
      // authorization_user: "a.kompanec",
      display_name: "Alice ¶€ĸøĸø",
      reister: true,
      // пароль
      password: SIP_PASS,
      user_agent: 'Scloud mobile'
    };
    let remoteAudio = new window.Audio();
    remoteAudio.autoplay = true;
    this.ua = new JsSIP.UA(configuration);

    // События регистрации клиента
    this.ua.on('connected', function (e: any) {
      console.log('==== connected', e);
    });
    this.ua.on('disconnected', function (e: any) {
      console.log('===== disconnected', e);
    });

    this.ua.on('registered', function (e: any) {
      console.log('===== registered', e);
    });
    this.ua.on('unregistered', function (e: any) {
      console.log('====== unregistered', e);
    });
    this.ua.on('registrationFailed', function (e: any) {
      console.log('====== registrationFailed' ,e);

    });
    this.ua.on('newRTCSession', function (e: any) {
      console.log('====== newRTCSession' ,e);
      if (e.originator !== 'local') {
        e.session.answer({mediaConstraints: { 'audio': true, 'video': false }});
      }
    });
    this.ua.on('newMessage', function (e: any) {
      console.log('====== newMessage' ,e);
    });
    this.ua.on('sipEvent', function (e: any) {
      console.log('====== sipEvent' ,e);
    });


// Запускаем
    this.ua.start();

    // Обработка событии исх. звонка
    let eventHandlers = {
      'progress': (e: any) => {
        console.log('=== call is in progress', e);

        if (this.session) {
          this.session.connection.ontrack = function (e: any) {
            console.log(e);
            remoteAudio.srcObject = e.streams[0];
          };
        }
      },
      'failed': (e: any) => {
        console.log('===== call failed with cause: ', e);
        // $('#call').css({'display' : 'flex'});
        // $('#hangup').css({'display' : 'none'});
      },
      'ended': (e: any) => {
        console.log('===== call ended with cause: ' + e.cause);
        // $('#call').css({'display' : 'flex'});
        // $('#hangup').css({'display' : 'none'});
      },
      'confirmed': (e: any) => {
        console.log('====== call confirmed');
        console.log(e);
      }
    };

    this.options = {
      'eventHandlers'    : eventHandlers,
      'mediaConstraints' : { 'audio': true, 'video': false }
    };

  }

  public call() {
    this.session = this.ua.call(`sip:${this.account}@${SIP_SERVER}`, this.options);
  }

  public hangup() {
    if (this.session) {
      this.session.terminate();
    }
  }

  public sendDtmf(event: KeyboardEvent) {
    console.log('event');
    console.log(event);
    console.log('this.text');
    console.log(this.text);
    this.text = '';

  }

  public downKey(event: KeyboardEvent) {
    console.log('event down');
    console.log(event);
  }
}
