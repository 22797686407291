<div>
  <h1>Клиент для звонков {{sipLogin}}</h1>
  <mat-form-field class="example-form-field" appearance="fill">
    <mat-label>Clearable input</mat-label>
    <input matInput type="tel" [(ngModel)]="account">
    <button *ngIf="account" matSuffix mat-icon-button aria-label="Clear" (click)="account=''">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
<br>

  <button mat-fab color="primary" (click)="call()">
    <mat-icon>phone</mat-icon>
  </button>

  <button mat-fab color="warn" (click)="hangup()">
    <mat-icon>phone</mat-icon>
  </button>
<br>
<!--  <input type="tel" inputmode="numeric" class="dtmf-input" (keyup)="sendDtmf($event)" (keydown)="downKey($event)" [(ngModel)]="text">-->
</div>
